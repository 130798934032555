import React from 'react';
import {BrowserRouter, Switch} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Routes from "../__Routes";
import '../../css/style.css';
import '../../css/responsive.css';

function App() {
  return (
    <BrowserRouter>
        <div className="App">
            <Switch>
                <Routes />
            </Switch>
        </div>
    </BrowserRouter>
  );
}

export default App;
