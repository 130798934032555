import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'


import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import PreviewSectionTitle from './SectionTitle'

// reactjs white 
import dashboard from '../images/demo/dashboard.jpg';
import dashboard2 from '../images/demo/dashboard.jpg';






const demos = [
    { name: 'React Version', image: dashboard, link: 'home' },
];

const demos2 = [
    { name: 'Html Version', image: dashboard2, link: 'index.html' },
];




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const PreviewDemo = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Fragment>
            <div className="previewDemoArea" id="demo">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <PreviewSectionTitle
                                title="Demo"
                                text="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
                            />
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <TabPanel value={value} index={0}>
                                    <div className="row">
                                        {demos.map((demo, i) => (
                                            <div key={i} className="col-sm-12 col-12">
                                                <div className="demoWrapper">
                                                    <a className="demoInfo" target="_blank"
                                                        href={`https://wirus.itech-theme.com/${demo.link}`}>{demo.name}</a>
                                                    <div className="demoImg">
                                                        {demo.new && <span> {demo.new} </span>}
                                                        <img src={demo.image} alt="" />
                                                    </div>
                                                    <div className="demoContent">
                                                        <h3>{demo.name}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </TabPanel>
                            </div>
                            <div className="col-lg-6">
                                <TabPanel value={value} index={0}>
                                    <div className="row">
                                        {demos2.map((demo, i) => (
                                            <div key={i} className="col-sm-12 col-12">
                                                <div className="demoWrapper">
                                                    <a className="demoInfo" target="_blank"
                                                        href={`https://wirus-html.itech-theme.com/${demo.link}`}>{demo.name}</a>
                                                    <div className="demoImg">
                                                        {demo.new && <span> {demo.new} </span>}
                                                        <img src={demo.image} alt="" />
                                                    </div>
                                                    <div className="demoContent">
                                                        <h3>{demo.name}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </TabPanel>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default PreviewDemo