import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Route Setting
import PreviewPage from "../Preview_page"

// Normal Route
import PrivateRoute from "../_PrivateRoute";
import PublicRoute from "../_PublicRoute";

export default function Routes() {
    return (
        <Switch>
            <PrivateRoute
                exact
                path="/preview"
                component={PreviewPage}
            />
            {/* <Route path="/login" component={LoginPage} />
            <Route path="/signup" component={SignupPage} /> */}
            <Route exact component={PreviewPage} />
        </Switch>
    );
}
